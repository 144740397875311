import React from 'react';
import { useState } from 'react'
// npm install emailjs-com --save 
import emailjs from 'emailjs-com'
// npm install --save reactstrap
// npm install = yarn
import { container, thanks, thankyou, thanksimage, emailContent, formWrap, formWrap1, formStyle, formGroup, button, formControl, inputFields } from "./email.module.css"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

const initialState = {
  name: '',
  surname: '',
  email: '',
  phone: '',
}

const Contact = () => {
  
  const [showResults, setShowResults] = useState(false)
  console.log ("showResults", showResults)
  const onClick = () => setShowResults(true)

  const Results = () => (
    <div className={thanks}>
    </div>
  )

  const [{ name, surname, email, phone}, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()

    console.log(
    " name: ", name, 
    " surname: ", surname, 
    " email: ", email, 
    " phone: ", phone, 
  )
    emailjs
      .sendForm(
      /* -ITUNES-RG31-WEDDING-PESTFLO*/
      'service_wt08csh', 'regina31', e.target, 'Ah1YDNtKgUX44BjcA' 
            /* RG31-MyTemplate*/
         // 'service_qra7ttk', 'template_TESTRG', e.target, 'user_8VEaqn0oNGu7ZWLJwP2Dm' 
            )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          /*alert("Email envoyé avec succès! Merci pour votre confiance");*/
          onClick()
        },
        (error) => {
          console.log(error.text)
        },
        // Clear and reset form input fields
         e.target.reset()
      )  
  }
  
  if ( showResults ? <Results /> : null ) {
    return  <motion.main
            initial={{
              opacity: 0.0,
              x: -200
            }}
            animate={{
              opacity: 1,
              x: 0
            }}
            exit={{
              opacity: 0.0,
              y: 200
            }}
            transition={{
              mass: 0.35,
              stiffness: 75,
              duration: 2.3,
            }}
          >
    <div className={thankyou}> 
              <div className={thanks}>
                    <h4> /* Email envoyé avec succès. Merci pour votre confiance */</h4>  
                    <h5> Regina </h5>  
              </div>
              <div>
                  <StaticImage className={thanksimage}
                  src="../assets/images/paysage-artiste.jpeg"
                  heigth= "auto"
                  position="relative"
                  alt="Bio"
                  placeholder="tracedSVG"
                  quality={40}   
                  />
              </div>
        
    </div>
  </motion.main>
  }
  return (
      <div className={container} >
         <div className={emailContent}>
              <div className={formWrap}>
                          <h1>Contact</h1>
                          <div className={formWrap1}>
                             </div>
                  <form className={formStyle} name='sentMessage' validate onSubmit={handleSubmit}>
                    <div className='row lp'>
                      <div className={inputFields}>      
                          <input
                              type='text'
                              id='name'
                              name='name'
                              className={formControl}
                              placeholder='Nom'
                              required
                              onChange={handleChange}
                          />
                          <input
                              type='email'
                              id='email'
                              name='email'
                              className={formControl}
                              placeholder='Email'
                              required
                              onChange={handleChange}
                          />

                          <motion.main
                          initial={{
                            opacity: 0.0,
                            x: -200
                          }}
                          animate={{
                            opacity: 1,
                            x: 0
                          }}
                          exit={{
                            opacity: 0.0,
                            y: 200
                          }}
                          transition={{
                            mass: 0.35,
                            stiffness: 75,
                            duration: 2.3,
                          }}
                        >
                        { showResults ? <Results /> : null } 
                          </motion.main>
                          <div>  
                           
                                <div className='col-md-12'>
                       
                                </div>
                                <div className='col-md-12'>
                                  <div className={formGroup}> 
                                    <textarea
                                    type='message'
                                    id='message'
                                    name='message'
                                    required
                                    className={formControl}
                                    placeholder='Message'
                                    onChange={handleChange}
                                    />
                                  </div>
                                </div>
                        </div>
                        <button 
                          className= {button}
                          type='submit' 
                       /*  onClick={onClick} */
                         
                          >
                          GO!
                        </button>  
                      </div>    
                    </div>
                  <div id='success'></div>
                </form> 
             </div>
          </div>
      </div>
  )
}
export default Contact